<template>
    <div class="container-auth">
        <div class="text-left">
            <img class="w-100" style="max-width:250px" src="@/assets/images/logo-horizontal.png" alt="">
        </div>
        <h3 class="mt-4" style="color:white; font-size:36px">
            Restore your password to learn
        </h3>
        <h6>
            Login with your email and password
        </h6>
        <AlertErrors />
        <div class="form-input mt-4">
            <span>Password</span>
            <b-form-input type="password" placeholder="*******" v-model="form.password" />
        </div>
        <div class="form-input mt-4">
            <span>Repeat password</span>
            <b-form-input type="password" v-model="r_password" placeholder="*******" />
        </div>
        <div class="mt-4">
            <router-link class="btn btn-link" :to="{ name: 'RecoverPassword' }">
                I forgot my password
            </router-link>
        </div>
        <div class="mt-4">
            <ButtonAction @click="onSubmit" title="Confirm Restore" :loading="loading" block="true" lg="true" :disabled="form.password == null" />
        </div>
        <div class="mt-4">
            <router-link class="btn btn-secondary w-100" :to="{ name: 'Signin' }">
                SignIn
            </router-link>
        </div>
    </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
    props: ['id', 'hash'],
    data () {
        return {
            form: {
                id: null,
                password: null
            },
            r_password: null,
            loading: false
        }
    },
    created (){
        this.form.id = this.id
    },
    methods: {
        ...mapActions('interceptors', ['setError']),
        ...mapActions('auth', ['passwordReset']),
        onSubmit () {
            this.loading = true
            this.passwordReset(this.form).then(response => {
                this.loading = false
                openNotification('Password changed, please signIn')
                this.$router.push({
                    name: 'Signin'
                })
            })
            this.loading = false
        }
    },
    watch: {
        r_password: function (val) {
            if(val != this.form.password) {
                this.setError(['Passwords not match'])
            } else {
                this.setError([])
            }
        }
    }
}
</script>
<style scoped>
    .container-auth{
        max-width: 50%;
        margin: auto;
    }
    h3{
        text-align: left;
        font-size: 40px;
        line-height: 48px;
        color: white;
    }
    h6{
        text-align: left;
        color: white;
        font-size: 22px;
        font-weight: 200;
        line-height: 32px;
    }
    .form-input{
        text-align: left;
        color: white;
    }
</style>