<template>
    <div class="nav-top">
        <div class="nav">
            <div class="row">
                <div class="col-1 hide-sm pt-3">
                    <img src="@/assets/images/logo.png" style="max-width: 70px; margin-left: 10px" alt="">
                </div>
                <div class="col-12 col-md-10 nav-links">
                    <User />
                    <Admin />
                </div>
                <div class="col-1 hide-sm text-right pt-3">
                    <b-avatar variant="secondary" :src="apiUrl + '/uploads/users/' + user.image " text="B7"></b-avatar>
                    <b-dropdown id="dropdown-1" variant="link" class="m-md-2">
                        <b-dropdown-item :to="{ name: 'Profile' }">Profile</b-dropdown-item>
                        <b-dropdown-item @click="close">Logout</b-dropdown-item>
                    </b-dropdown>   
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import User from './partials/User.vue'
import Admin from './partials/Admin.vue'
import { mapActions, mapState } from 'vuex'
export default {
    components: {
        User,
        Admin
    },
    created () {
        this.getUserInfo()
    },
    methods: {
        ...mapActions('auth', ['logout', 'getUserInfo']),
        close () {
            this.logout().then(() =>{
                this.$router.push({ name: 'Signin' })
            })
        }
    },
    computed: {
        ...mapState('auth', ['user'])
    }
}
</script>
<style>
    .nav-links{
        display: flex;
        padding: 30px 10px 13px 10px;
        text-decoration: none;
        color: #efefef;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
    }
    .nav-links a {
        color: #efefef;
        text-decoration: none !important;
    }
    .nav-links .nav-item{
        padding-left: 10px;
        padding-right: 10px;
    }
    .nav-top .nav{
        display: initial !important;
    }
    @media (max-width: 767.98px) { 
        .hide-sm{
            display: none;
        }
     }

</style>