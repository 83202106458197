<template>
  <div>
    <div class="row">
      <div class="col mb-3">
        <b-card class="bg-first h-100" no-body>
          <div class="bg-card-dots">
            <div class="bg-icon">
                <div class="card-body">
                    <div class="row">
                      <h6 v-if="user.range">
                        {{ user.range.name }}
                      </h6>
                      <b-progress :value="calcPercentage(user.sold, user.range.quantity)" max="100" show-progress></b-progress>
                      <h6 class="mt-1">
                        ${{ formatCurrency(user.sold) }} of ${{ formatCurrency(user.range.quantity) }} ({{ calcPercentage(user.sold, user.range.quantity) }}%)
                      </h6>
                    </div>
                </div>
            </div>
          </div>
        </b-card>
      </div>
      <div class="col mb-3">
        <b-card class="bg-first h-100" no-body>
          <div class="bg-card-dots">
            <div class="bg-icon">
                <div class="card-body">
                    <div class="row">
                        <div class="col-9">
                            <h6>Initial Capital</h6>
                            <h2>${{ formatCurrency(actives) }}</h2>
                        </div>
                        <div class="col-3 text-center">
                            <div class="icon-cintainer mt-3">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.3549 17.087C21.8819 13.622 21.8819 8.06302 18.3549 4.59802C14.8279 1.13302 9.17088 1.13302 5.64488 4.59802C2.11888 8.06302 2.11788 13.622 5.64488 17.087" stroke="#E3C87A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.09961 21H20.0996H4.09961Z" stroke="#E3C87A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M12 6V7.25" stroke="#E3C87A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M12 16V14.75" stroke="#E3C87A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M14.487 9C14.422 8.024 13.617 7.25 12.625 7.25H11.255C10.285 7.25 9.5 8.036 9.5 9.005C9.5 9.81 10.048 10.512 10.828 10.708L13.171 11.296C13.952 11.492 14.499 12.194 14.499 12.999C14.499 13.969 13.713 14.754 12.744 14.754H11.374C10.38 14.754 9.575 13.978 9.511 13" stroke="#E3C87A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </b-card>
      </div>
      <div class="col mb-3">
        <b-card class="bg-second h-100" no-body>
          <div class="bg-card-dots">
            <div class="bg-icon">
                <div class="card-body">
                    <div class="row">
                        <div class="col-9">
                            <h6>Active Memberships</h6>
                            <h2>{{ membershipsActive ? membershipsActive.length : 0 }}</h2>
                        </div>
                        <div class="col-3 text-center">
                            <div class="icon-cintainer mt-3">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.3549 17.087C21.8819 13.622 21.8819 8.06302 18.3549 4.59802C14.8279 1.13302 9.17088 1.13302 5.64488 4.59802C2.11888 8.06302 2.11788 13.622 5.64488 17.087" stroke="#E3C87A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.09961 21H20.0996H4.09961Z" stroke="#E3C87A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M12 6V7.25" stroke="#E3C87A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M12 16V14.75" stroke="#E3C87A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M14.487 9C14.422 8.024 13.617 7.25 12.625 7.25H11.255C10.285 7.25 9.5 8.036 9.5 9.005C9.5 9.81 10.048 10.512 10.828 10.708L13.171 11.296C13.952 11.492 14.499 12.194 14.499 12.999C14.499 13.969 13.713 14.754 12.744 14.754H11.374C10.38 14.754 9.575 13.978 9.511 13" stroke="#E3C87A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </b-card>
      </div>
      <div class="col mb-3">
        <b-card class="bg-third h-100" no-body>
          <div class="bg-card-dots">
            <div class="bg-icon">
            <div class="card-body">
                <div class="row">
                    <div class="col-9">
                        <h6>Referred users</h6>
                        <h2>{{ user.referrals ? user.referrals : 0 }}</h2>
                    </div>
                    <div class="col-3 text-center">
                        <div class="icon-cintainer mt-3">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.3549 17.087C21.8819 13.622 21.8819 8.06302 18.3549 4.59802C14.8279 1.13302 9.17088 1.13302 5.64488 4.59802C2.11888 8.06302 2.11788 13.622 5.64488 17.087" stroke="#E3C87A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.09961 21H20.0996H4.09961Z" stroke="#E3C87A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12 6V7.25" stroke="#E3C87A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12 16V14.75" stroke="#E3C87A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M14.487 9C14.422 8.024 13.617 7.25 12.625 7.25H11.255C10.285 7.25 9.5 8.036 9.5 9.005C9.5 9.81 10.048 10.512 10.828 10.708L13.171 11.296C13.952 11.492 14.499 12.194 14.499 12.999C14.499 13.969 13.713 14.754 12.744 14.754H11.374C10.38 14.754 9.575 13.978 9.511 13" stroke="#E3C87A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
                </div>
            </div>
          </div>
        </b-card>
      </div>
      <div class="col mb-3">
        <b-card class="bg-four h-100" no-body>
          <div class="bg-card-dots">
            <div class="bg-icon">
                <div class="card-body pt-4">
                    <Copy color="white" class="mt-1 mb-2" :address="'https://academy.eost.io/#/auth/signup/' + user.username" />
                </div>
            </div>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
    created () {
        this.getMembershipsActive().then(() => {
            this.membershipsActive.forEach(element => {
                this.actives = element.membership.price + this.actives
            });
        })
    },
    data () {
        return {
            actives: 0
        }
    },
    methods: {
        ...mapActions('membership', ['getMembershipsActive']),
        calcPercentage (sold, quantity) {

            if(sold > 0) {
                let perce = (sold * 100) / quantity

                return perce.toFixed(2)
            } else {
                return 0
            }
        },
        formatCurrency (x) {
            if(x != null && x >= 0) {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else {
                return 0
            }
        }
    },
    computed: {
        ...mapState('auth', ['user']),
        ...mapState('membership', ['membershipsActive'])
    }
}
</script>
<style>
.bg-card-dots {
  background-image: url("/images/dots.png");
  background-position: left;
  background-repeat: no-repeat;
}
.icon-cintainer{
    padding: 10px;
    background: rgba(255, 255, 255, 0.05);
    width: 50px;
    border-radius: 15px;
}
.bg-icon{
    background-image: url('/images/b7-gris.png');
    background-position: right;
    background-repeat: no-repeat;
}
.bg-first{
  background: linear-gradient(90deg, #8D7734 0%, #1A160D 100%) !important; 
}
.bg-second{
  background: linear-gradient(90deg, #698B32 0%, #1A1E0D 100%) !important;
}
.bg-third{
  background: linear-gradient(90deg, #26808D 0%, #0C181A 100%) !important;
}
.bg-four{
  background: linear-gradient(90deg, #94395B 0%, #1D0E14 100%) !important;
}
</style>
