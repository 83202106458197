<template>
    <div class="container-auth">
        <b-card class="p-3">
            <div class="text-left">
                <img class="w-100" style="max-width:250px" src="@/assets/images/logo-horizontal.png" alt="">
            </div>
            <h3 class="mt-4" style="color:white; font-size:36px">
                Validate your login
            </h3>
            <h6>
                Enter the code sended to your email
            </h6>
            <AlertErrors />
            <div class="form-input mt-4">
                <span>Validation code</span>
                <b-form-input v-model="form.code" placeholder="UYGYASD" />
                <div class="text-right mt-1">
                    <b-button variant="link" @click="sendCode()">
                        Send validation code
                    </b-button>
                </div>
            </div>
            <div class="mt-4">
                <ButtonAction @click="checkCode" title="Check validation code" :loading="loading" block="true" lg="true" :disabled="form.code == null" />
            </div>
            <div class="mt-4">
                <router-link class="btn btn-link w-100" :to="{ name: 'Signin' }">
                    SignIn
                </router-link>
            </div>
        </b-card>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    data () {
        return {
            form: {
                code: null
            },
            loading: false
        }
    },
    created () {
    },
    methods: {
        ...mapActions('auth', ['sendValidation', 'validateCode']),
        sendCode() {
            this.loading = true
            this.sendValidation().then(() => {
                openNotification('Validation code sent, please check your email')
                this.loading = false
            })
        },
        checkCode () {
            this.loading = true
            this.validateCode(this.form).then(() => {
                this.isOkay = true
                this.loading = false
                this.$router.push({
                    path: '/'
                })
            })
            this.loading = false
        }
    }
}
</script>
<style scoped>
    .container-auth{
        max-width: 50%;
        margin: auto;
    }
    h3{
        text-align: left;
        font-size: 40px;
        line-height: 48px;
        color: white;
    }
    h6{
        text-align: left;
        color: white;
        font-size: 22px;
        font-weight: 200;
        line-height: 32px;
    }
    .form-input{
        text-align: left;
        color: white;
    }
</style>