<template>
    <div>
        <Navbar />
        <div class="home-full mt-3">
            <Breadcum />
            <AlertErrors />
            <router-view class="mt-4"/>
        </div>
    </div>
</template>
<script>
import Sidebar from '../components/layout/Sidebar.vue'
import Navbar from '../components/layout/Navbar.vue'
import Breadcum from '../components/layout/Breadcum.vue'
export default {
    components: {
        Sidebar,
        Navbar,
        Breadcum
    }
}
</script>