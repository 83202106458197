import axios from 'axios'

export default {
  namespaced: true,
  state: {
    withdraws: []
  },
  getters: {},
  mutations: {
    GET_WITHDRAWS (state, data) {
        state.withdraws = data
    },
    UPDATE_WITHDRAWS (state, data) {
      let objIndex = state.withdraws.data.findIndex((obj => obj.id == data.id));
      state.withdraws[objIndex] = data
    }
  },
  actions: {
    async getWithdrawsAdmin (context, payload) {
      const response = await axios.get(`/api/v1/withdraw/${payload.username}/${payload.page}`)
      context.commit('GET_WITHDRAWS', response.data)
      return response.data
    },
    async storeWithdraw (context, data) {
        const response = await axios.post('/api/v1/withdraw/request', data)
        return response.data
    },
    async updateWithdraw (context, payload) {
      const response = await axios.post(`/api/v1/withdraw/update`, payload)
      context.commit('UPDATE_WITHDRAWS', response.data)
      return response.data
    }
  }
}
