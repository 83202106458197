<template>
    <div>
        <div class="row">
            <div class="col-12 mb-5">
                <Banner v-if="windowWidth > 900"/>
                <BannerMobile v-else/>
            </div>
            <div class="col-12 mb-3">
                <Stats />
            </div>
            <div class="col-12 col-sm-6 col-md-12 col-lg-4 mb-4">
                <Team />
            </div>
            <div class="col-12 col-sm-6 col-md-12 col-lg-4 mb-4">
                <Wallets />
            </div>
            <div class="col-12 col-sm-6 col-md-12 col-lg-4 mb-4">
                <Benefits />
            </div>
        </div>
    </div>
</template>
<script>
import Benefits from './Benefits.vue'
import Wallets from './Wallets.vue'
import Team from './Team.vue'
import Referred from './Referred.vue'
import Banner from './Banner.vue'
import Stats from './Stats.vue'
import BannerMobile from './BannerMobile.vue'
import { mapState } from 'vuex'

export default {
    components: {
        Benefits,
        Wallets,
        Team,
        Referred,
        Banner,
        Stats,
        BannerMobile
    },
    data() {
        return {
            windowWidth: window.innerWidth
        }
    },
    mounted() {
        window.onresize = () => {
            this.windowWidth = window.innerWidth
        }
    },
    computed: {
        ...mapState('auth', ['user'])
    }
    
}
</script>