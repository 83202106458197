<template>
    <div>
        <div class="container">
            <img src="/images/bg-dashboard-mobile.png" alt="Snow" style="width:100%;">
            <div class="text">
                <img src="@/assets/images/dashboard-main.png" class="w-100" alt="">
                <h1 class="text-white">
                    Start earning while learn
                </h1>
                <div class="mt-4 text-white" style="font-size:15px">
                    Yan earn while you learn, you can make Staking in which you will get benefits and monthly earnings
                </div>
                <b-button variant="primary" class="mt-4" size="sm" :to="{ name: 'Membership' }">
                    Go to Memberships
                </b-button>
            </div>
        </div>
    </div>
</template>
<style scoped>
    .container {
  position: relative;
  text-align: center;
  color: white;
}

/* Bottom left text */
.text {
  position: absolute;
  top: 20px;
  text-align: left;
}

.position-right {
  position: absolute;
  top: 0%;
  right: 100px;
}

</style>