<template>
    <div class="h-100">
        <b-card title="My wallets" class="bg-wallet h-100">
            <div id="chart" />
        </b-card>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import ApexCharts from 'apexcharts'
export default {
    data () {
        return {
            loading: false,
            balances: [],
            names: [] 
        }
    },
    created () {
        this.getData()
    },
    methods: {
        ...mapActions('wallet', ['getWallets']),
        getData () {
            this.loading = true
            this.getWallets().then(() => {
                this.filterWallet()
            })
        },
        filterWallet() {
            this.wallets.forEach(element => {
                this.balances.push(element.balance_usd)
                this.names.push(element.asset.name + ' | ' + element.balance_usd)
            });
            this.loading = false
            this.initTimelineChart()
        },
        initTimelineChart () {
            const options = {
                "chart": {
                    "height": 250,
                    "type": "pie",
                    "toolbar": {
                        "show": false
                    }
                },
                "series": this.balances,
                "labels": this.names
            }
            var chart = new ApexCharts(document.querySelector('#chart'),
                options
            );
            chart.render();
        }
    },
    computed: {
        ...mapState('wallet', ['wallets'])
    }
}
</script>
<style>
    .bg-wallet{
        background: linear-gradient(90deg, #26808D 0%, #0C181A 100%) !important;
    }
</style>