<template>
    <div>
        <div class="container">
            <img src="/images/bg-dashboard.png" alt="Snow" style="width:100%;">
            <div class="row" style="position: absolute; top:20px">
                <div class="col-12 col-md-7 text-left" style="padding-top:120px; padding-left:100px">
                    <h1>
                        Start earning while you learn
                    </h1>
                    <div class="mt-4">
                        With B7 Academy you can earn while you learn, you can make Staking or buy a membership in which you will get benefits and monthly earnings
                    </div>
                    <b-button variant="primary" class="mt-4" size="lg" :to="{ name: 'Membership' }">
                        Go to Memberships
                    </b-button>
                </div>
                <div class="col-12 col-md-5" style="padding-right:100px">
                    <img src="@/assets/images/dashboard-main.png" class="w-100 mt-5" alt="">
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
    .container {
  position: relative;
  text-align: center;
  color: white;
}

/* Bottom left text */
.position-left {
  position: absolute;
  top: 30%;
  left: 100px;
  text-align: left;
  max-width: 40%;
}

.position-right {
  position: absolute;
  top: 0%;
  right: 100px;
}

</style>