<template>
    <div class="h-100">
        <b-card class="bg-white-gold h-100">
            <div class="d-flex">
                <div class="custom-badged p-2 mr-3">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8 4H16C16.5523 4 17 4.44772 17 5V11C17 13.7614 14.7614 16 12 16V16C9.23858 16 7 13.7614 7 11V5C7 4.44772 7.44772 4 8 4Z" stroke="#1d2918" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M16.6729 12.7801L18.5649 11.8341C19.7506 11.2412 20.4996 10.0293 20.4996 8.70361V7.3667C20.4996 6.88345 20.1079 6.4917 19.6246 6.4917H16.9996" stroke="#1d2918" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12 20V16" stroke="#1d2918" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M16 20H8" stroke="#1d2918" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M7.32677 12.7801L5.43477 11.8341C4.24903 11.2412 3.50001 10.0293 3.5 8.70361V7.3667C3.5 6.88345 3.89175 6.4917 4.375 6.4917H7" stroke="#1d2918" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <h2 class="ml-3 mr-3 card-title">
                    Benefits
                </h2>
                <b-button variant="primary" size="sm">
                    Pro
                </b-button>
            </div>
            <div class="mt-3">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.49805 14.0008L9.99909 11.3327L12.5001 14.0008L16.5018 9.99915" stroke="#7f5e2c" stroke-width="1.41" stroke-linecap="round" stroke-linejoin="round"/>
                <rect x="2.99609" y="2.99622" width="18.0075" height="18.0075" rx="5" stroke="#7f5e2c" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                Signals
            </div>
            <div class="mt-3">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 5L10 19H6L6 5L10 5Z" stroke="#7f5e2c" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18 8L18 16L14 16L14 8L18 8Z" stroke="#7f5e2c" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M4 12H6" stroke="#7f5e2c" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10 12H14" stroke="#7f5e2c" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M18 12H20" stroke="#7f5e2c" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                Trading Live
            </div>
            <div class="mt-3">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 20H5C3.89543 20 3 19.1046 3 18V5C3 3.89543 3.89543 3 5 3H14C15.1046 3 16 3.89543 16 5V9" stroke="#7f5e2c" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M7.8379 13.1617C6.92183 12.2437 6.92262 10.7571 7.83967 9.84003C8.75671 8.92299 10.2433 8.9222 11.1613 9.83827" stroke="#7f5e2c" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M7 17H6" stroke="#7f5e2c" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M13 6H12" stroke="#7f5e2c" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M21 16H11" stroke="#7f5e2c" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M19 13H13C11.8954 13 11 13.8954 11 15V19C11 20.1046 11.8954 21 13 21H19C20.1046 21 21 20.1046 21 19V15C21 13.8954 20.1046 13 19 13Z" stroke="#7f5e2c" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                Withdraws
            </div>
            <div class="mt-3">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19 8V5C19 3.89543 18.1046 3 17 3H5C3.89543 3 3 3.89543 3 5V19" stroke="#7f5e2c" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M7 3V17" stroke="#7f5e2c" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11 17H5C3.89543 17 3 17.8954 3 19V19C3 20.1046 3.89543 21 5 21H10" stroke="#7f5e2c" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M21 20.498V19.998C21 18.8935 20.1046 17.998 19 17.998H15.5C14.3954 17.998 13.5 18.8935 13.5 19.998V20.498C13.5 20.7742 13.7239 20.998 14 20.998H20.5C20.7761 20.998 21 20.7742 21 20.498Z" stroke="#7f5e2c" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <circle cx="17.25" cy="13.5" r="2" stroke="#7f5e2c" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                Trading Plans
            </div>
            <div class="mt-3">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 17.0796V20.9999" stroke="#7f5e2c" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M3 21V19.5285C3 18.0985 4.00938 16.8672 5.41165 16.5868L8.25765 16.0176C8.49288 15.9705 8.73717 16.0188 8.93677 16.1519V16.1519C10.7917 17.3885 13.2083 17.3885 15.0633 16.1519V16.1519C15.2629 16.0188 15.5072 15.9705 15.7424 16.0176L18.5884 16.5868C19.9907 16.8672 21 18.0985 21 19.5285V21" stroke="#7f5e2c" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M16 7.39832V9.99998C16 12.2091 14.2091 14 12 14V14C9.79086 14 8 12.2091 8 9.99998V7.39832" stroke="#7f5e2c" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M14.1009 13.4054L14.8249 16.3019" stroke="#7f5e2c" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M9.90109 13.4054L9.17704 16.3018" stroke="#7f5e2c" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.09686 6.45618L10.5514 8.66141C11.4644 9.11286 12.5356 9.11286 13.4486 8.66141L17.9031 6.45618C18.2684 6.27761 18.5 5.90656 18.5 5.5C18.5 5.09344 18.2684 4.72239 17.9031 4.54382L13.4486 2.33859C12.5356 1.88714 11.4644 1.88714 10.5514 2.33859L6.09686 4.54382C5.73161 4.72239 5.5 5.09344 5.5 5.5C5.5 5.90656 5.73161 6.27761 6.09686 6.45618Z" stroke="#7f5e2c" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M18.5 5.53455V8.13455" stroke="#7f5e2c" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                Academy
            </div>
            <b-button class="mt-4 w-100" size="lg" variant="outline-primary" :to="{ name: 'Membership' }">
                View available plans
            </b-button>
        </b-card>
    </div>
</template>
<style>
    .bg-white-gold{
        background: white !important;
    }
    .bg-white-gold .card-title{
        color: #7f5e2c;
    }
    .bg-white-gold div{
        color: #7f5e2c;
    }
    .bg-white-gold .btn-outline-primary{
        background: #1d2918 !important;
    }
</style>